import {
  Component,
  EventEmitter,
  OnChanges,
  OnInit,
  Output,
} from '@angular/core';
import {
  projectInterface,
  ProjectsService,
} from 'src/app/services/project.service';

import { AngularFireAuth } from '@angular/fire/compat/auth';
import { ActivatedRoute, RouterModule } from '@angular/router';
// import { NavigationEnd, NavigationError, NavigationStart, Router, Event } from '@angular/router';
import {
  CompanyService,
  companyInterface,
} from 'src/app/services/company.service';
import { CommonModule } from '@angular/common';
import {} from '@angular/common/http';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { LayoutService } from '../layout.service';

@Component({
  selector: 'app-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.scss'],
  standalone: true,
  providers: [ProjectsService, CompanyService],
  imports: [
    CommonModule,
    RouterModule,

    // TODO: `HttpClientModule` should not be imported into a component directly.
    // Please refactor the code to add `provideHttpClient()` call to the provider list in the
    // application bootstrap logic and remove the `HttpClientModule` import from this component.
    // HttpClientModule,
    MatSidenavModule,
    MatToolbarModule,
    MatButtonModule,
    MatIconModule,
    MatListModule,
    // MessagingModule
  ],
})
export class SideMenuComponent implements OnInit {
  constructor(
    public auth: AngularFireAuth,
    public projectService: ProjectsService,
    private route: ActivatedRoute,
    private companyService: CompanyService,
    private layoutService: LayoutService // private router: Router
  ) {
    // this.router.events.subscribe((event: Event) => {
    //   if (event instanceof NavigationStart) {
    //     // Show loading indicator
    //     this.getCompanyId();
    //   }

    //   if (event instanceof NavigationEnd) {
    //     // Hide loading indicator
    //     this.getCompanyId();
    //   }

    //   if (event instanceof NavigationError) {
    //     // Hide loading indicator

    //     // Present error to user
    //     console.log(event);
    //   }
    // });
    this.layoutService.companyId$.subscribe((companyId) => {
      if (companyId !== undefined) {
        this.companyId = +companyId;
        this.getProjects();
        this.getCompany();
      } else {
        console.log('Company ID is undefined');
      }
    });
  }

  projects: projectInterface[] = [];
  isLoading: boolean = false;
  company?: companyInterface = undefined;
  companyId?: number;

  @Output() clickOnVoice: EventEmitter<void> = new EventEmitter();

  ngOnInit() {
    this.getCompanyId();
  }

  clickOnVoiceButton() {
    this.clickOnVoice.emit();
  }

  getCompanyId() {
    // console.log('Getting company ID');
    this.route.paramMap.subscribe((params) => {
      // console.log('Getting company ID');
      let companyId = params.get('companyId') || undefined;
      // console.log('Company ID:', companyId);
      if (companyId !== undefined) {
        this.companyId = +companyId;
        this.getProjects();
        this.getCompany();
      } else {
        setTimeout(() => {
          this.getCompanyId();
        }, 1000);
      }
    });
  }

  getCompany(): void {
    if (
      this.companyId !== undefined &&
      this.companyId !== null &&
      this.companyId !== 0
    ) {
      this.companyService.getCompanyDetail(this.companyId, {}).subscribe({
        next: (res) => {
          this.company = res;
        },
        error: (error) => {
          console.error(error);
        },
      });
    } else {
      console.warn('Company ID is undefined or null or 0');
      // setTimeout(() => {
      //   this.getCompany();
      //   this.getProjects();
      // }, 1000);
    }
  }
  getProjects(): void {
    if (this.companyId !== undefined) {
      this.isLoading = true;
      this.projectService.getProjectList(this.companyId).subscribe(
        {
          next: (res) => {
            this.projects = res.results;
            this.isLoading = false;
          },
          error: (error) => {
            console.log(error);
            // this.isLoading = true;
            this.isLoading = false;
          },
        }
        // map(
        // )
      );
    } else {
      this.projects = [];
      console.error('Company ID is undefined');
    }
  }
}
