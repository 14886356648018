<div>
  <!-- <h3 class="mb-0">Dashboard</h3>
  <mat-list role="list">
    <mat-list-item (click)="clickOnVoiceButton()" role="listitem">Item 1</mat-list-item>
    <mat-divider></mat-divider>
    <mat-list-item (click)="clickOnVoiceButton()" role="listitem">Item 2</mat-list-item>
    <mat-divider></mat-divider>
    <mat-list-item (click)="clickOnVoiceButton()" role="listitem">Item 3</mat-list-item>
    <!-- <mat-divider></mat-divider> -- >
  </mat-list> -->
  @if (companyId) {
  <h3 class="mb-0" (click)="clickOnVoiceButton()">
    Projects
    <a [routerLink]="['/', companyId, 'projects', 'list']">
      <mat-icon>view_list</mat-icon>
    </a>
  </h3>
  <mat-list role="list">
    <mat-list-item (click)="clickOnVoiceButton()" role="listitem">
      <a [routerLink]="['/', companyId, 'projects', 'new']">Create Project </a>
    </mat-list-item>
    <mat-divider></mat-divider>
    <!-- {{projects} -->
    <!-- <ng-container *ngFor="let project of projects; let last as last"> -->
    @for (project of projects; track project; let last = $last) {
    <mat-list-item (click)="clickOnVoiceButton()" role="listitem">
      <a [routerLink]="['/', companyId, project.id, 'tasks', 'list']">
        {{ project.title }}
      </a>
    </mat-list-item>
    @if (!last) {
    <mat-divider></mat-divider>
    }
    <!-- <mat-divider *ngIf="!last"></mat-divider> -->
    }
    <!-- </ng-container> -->
    <!-- <mat-list-item (click)="clickOnVoiceButton()" role="listitem">Item 3</mat-list-item> -->
    <!-- <mat-divider></mat-divider> -->
  </mat-list>
  <h3 class="mb-0">Company</h3>
  <mat-list role="list">
    <mat-list-item (click)="clickOnVoiceButton()" role="listitem">
      <a [routerLink]="['/', companyId, 'users', 'list']"> Users </a>
    </mat-list-item>
    <mat-list-item (click)="clickOnVoiceButton()" role="listitem">
      <a [routerLink]="['/', 'company', companyId, 'team', 'list']"> Team </a>
    </mat-list-item>
    <!-- <mat-divider></mat-divider>
    <mat-list-item (click)="clickOnVoiceButton()" role="listitem">Item 2</mat-list-item>
    <mat-divider></mat-divider>
    <mat-list-item (click)="clickOnVoiceButton()" role="listitem">Item 3</mat-list-item> -->
    <!-- <mat-divider></mat-divider> -->
  </mat-list>
  <mat-divider></mat-divider>
  <h3 class="mb-0">Daily Resume</h3>
  <mat-list role="list">
    <mat-list-item (click)="clickOnVoiceButton()" role="listitem">
      <a [routerLink]="['/', companyId, 'daily-resume']"> Daily resume list </a>
    </mat-list-item>
    <!-- <mat-divider></mat-divider>
    <mat-list-item (click)="clickOnVoiceButton()" role="listitem">Item 2</mat-list-item>
    <mat-divider></mat-divider>
    <mat-list-item (click)="clickOnVoiceButton()" role="listitem">Item 3</mat-list-item> -->
    <!-- <mat-divider></mat-divider> -->
  </mat-list>
  <mat-divider></mat-divider>
  <h3 class="mb-0">Personal</h3>
  <mat-list role="list">
    <mat-list-item (click)="clickOnVoiceButton()" role="listitem">
      <a [routerLink]="['/', 'personal-plan']"> Plan </a>
    </mat-list-item>
    <!-- <mat-divider></mat-divider>
    <mat-list-item (click)="clickOnVoiceButton()" role="listitem">Item 2</mat-list-item>
    <mat-divider></mat-divider>
    <mat-list-item (click)="clickOnVoiceButton()" role="listitem">Item 3</mat-list-item> -->
    <!-- <mat-divider></mat-divider> -->
  </mat-list>
  <mat-divider></mat-divider>
  } @if (auth.user | async; as user) {
  <div class="d-flex justify-content-start align-items-center mt-3">
    <div
      style="
      display: inline-block;
      width: 2rem;
      height: 2rem;
      background-image: url('{{ user.photoURL }}');
      background-size: cover;
      background-position: center;
    "
    ></div>
    <div class="ps-2">
      <!-- {{ user.displayName }} -->
      <small class="d-block">
        {{ user.displayName }}
      </small>
      @if (company) {
      <small class="d-block">
        {{ company.name }}
      </small>
      }
    </div>
    <div class="px-2 ms-auto">
      <!-- {{ user.displayName }} -->
      <div class="d-inline-flex align-items-center justify-content-center">
        <small class="d-block italic" (click)="clickOnVoiceButton()">
          <a [routerLink]="['/', 'companies']">
            <!-- logout -->
            <mat-icon>cached</mat-icon>
          </a>
        </small>
        <small class="d-block italic" (click)="clickOnVoiceButton()">
          <a [routerLink]="['/', 'auth', 'logout']">
            <!-- logout -->
            <mat-icon>logout</mat-icon>
          </a>
        </small>
      </div>
    </div>
  </div>
  }
  <!-- <mat-list>
<div mat-subheader>Folders</div>
<mat-list-item (click)="clickOnVoiceButton()" *ngFor="let folder of folders">
  <mat-icon matListItemIcon>folder</mat-icon>
  <div matListItemTitle>{{folder.name}}</div>
  <div matListItemLine>{{folder.updated | date}}</div>
</mat-list-item>
<div mat-subheader>Notes</div>
<mat-list-item (click)="clickOnVoiceButton()" *ngFor="let note of notes">
  <mat-icon matListItemIcon>note</mat-icon>
  <div matListItemTitle>{{note.name}}</div>
  <div matListItemLine>{{note.updated | date}}</div>
</mat-list-item>
</mat-list> -->
</div>
