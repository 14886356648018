import { Component, OnInit, ViewChild } from '@angular/core';
import { FirebaseApp } from '@angular/fire/app';
// import { Messaging } from '@angular/fire/messaging';
import { environment } from '@environments/environment';
// import { Messaging } from '@angular/fire/messaging';
import { getMessaging, getToken } from '@angular/fire/messaging';

import { MessagingService } from '@app/services/messaging.service';
import {
  MatDrawerToggleResult,
  MatSidenav,
  MatSidenavModule,
} from '@angular/material/sidenav';
import { ProjectsService } from '@app/services/project.service';
import { CompanyService } from '@app/services/company.service';

import { RouterModule } from '@angular/router';
import {} from '@angular/common/http';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatToolbarModule } from '@angular/material/toolbar';
import { SideMenuComponent } from '../side-menu/side-menu.component';

@Component({
  selector: 'app-authorized-layout',
  templateUrl: './authorized-layout.component.html',
  styleUrls: ['./authorized-layout.component.scss'],
  standalone: true,
  providers: [ProjectsService, CompanyService],
  imports: [
    RouterModule,
    MatSidenavModule,
    MatToolbarModule,
    MatButtonModule,
    MatIconModule,
    MatListModule,
    SideMenuComponent,
  ],
})
export class AuthorizedLayoutComponent implements OnInit {
  mobileMenuIsOpen = false;
  @ViewChild('mobileMenu') mobileMenu!: MatSidenav;

  constructor(
    // private afMessaging: AngularFireMessaging,
    // private fireMessaging: Messaging
    // private angularFireMessaging: AngularFireMessaging,
    // private fireMessaging: AngularFireMessaging,
    private messagingService: MessagingService
  ) {}

  toggleMobileMenu() {
    console.log('Toggling mobile menu.');
    if (this.mobileMenuIsOpen) {
      this.closeMobileMenu();
    } else {
      this.openMobileMenu();
    }
  }

  openMobileMenu() {
    this.mobileMenu
      .open()
      .then((res: MatDrawerToggleResult) => {
        console.log('Mobile menu opened.', res);
        this.mobileMenuIsOpen = true;
      })
      .catch((err) => {
        console.error('Error occurred while opening mobile menu.', err);
      });
  }
  closeMobileMenu() {
    this.mobileMenu
      .close()
      .then((res: MatDrawerToggleResult) => {
        console.log('Mobile menu closed.', res);
        this.mobileMenuIsOpen = false;
      })
      .catch((err) => {
        console.error('Error occurred while opening mobile menu.', err);
      });
  }

  clickOnVoice() {
    this.closeMobileMenu();
  }

  // ngOnInit() {
  //   // this.fireMessaging.app. .getToken().then((currentToken) => {
  //   //   if (currentToken) {
  //   //     console.log('Got FCM token:', currentToken);
  //   //     // Send the token to your server and update the UI if necessary
  //   //   } else {
  //   //     console.log('No FCM token available. Request permission to generate one.');
  //   //     // Show permission request UI
  //   //   }
  //   // }
  //   // this.afMessaging.messaging.subscribe((messaging) => {
  //   //   messaging.onMessage((message) => {
  //   //     console.log('Message received:', message);
  //   //     // Handle the received message here
  //   //   });
  //   // });
  // }

  // requestPermission() {
  //   // this.afMessaging.requestPermission.subscribe(
  //   //   () => {
  //   //     console.log('Permission granted!');
  //   //     // Handle successful permission grant
  //   //   },
  //   //   (error) => {
  //   //     console.log('Permission denied:', error);
  //   //     // Handle permission denied or error
  //   //   }
  //   // );
  // }

  // message:any = null;

  ngOnInit(): void {
    // this.requestPermission();
    let requestPermissionSubject = this.messagingService.requestPermission();
    requestPermissionSubject.subscribe({
      next: (permission) => {
        console.log('Permission!', permission);
        this.messagingService.requestForToken().then((token) => {
          console.log('A Token received.', token);
          console.log('A messagingService.requestForToken', token);
          this.messagingService
            .createDeviceToken({}, { token: token })
            .subscribe({
              next: (data) => {
                console.log(
                  'A Device Token created or refreshed successfully.',
                  data
                );
              },
              error: (error) => {
                console.error(
                  'Error occurred while creating Device Token.',
                  error
                );
              },
            });
          // return token;
        });
      },
      error: (error) => {
        console.error('Error occurred while requesting permission.', error);
      },
    });
    // setTimeout(() => {

    // }, 3000);
    // this.listen();
  }

  getMessagingToken() {
    const messaging = getMessaging();
    getToken(messaging, { vapidKey: environment.firebase.vapidKey })
      .then((currentToken) => {
        if (currentToken) {
          console.log('Got FCM token:', currentToken);
          // Send the token to your server and update the UI if necessary
          // ...
        } else {
          // Show permission request UI
          console.log(
            'No registration token available. Request permission to generate one.'
          );
          // ...
        }
      })
      .catch((err) => {
        console.log('An error occurred while retrieving token. ', err);
        // ...
      });
  }
  // requestPermission() {
  //   this.fireMessaging
  //   // .then(
  //   //   {
  //   //     next: (app) => {
  //   //     },
  //   //   }
  //   // );

  //   const messaging = getMessaging();
  //   getToken(messaging,
  //    { vapidKey: environment.firebase.vapidKey}).then(
  //      (currentToken) => {
  //        if (currentToken) {
  //          console.log("Hurraaa!!! we got the token.....");
  //          console.log(currentToken);
  //        } else {
  //          console.log('No registration token available. Request permission to generate one.');
  //        }
  //    }).catch((err) => {
  //       console.log('An error occurred while retrieving token. ', err);
  //   });
  // }
  listen() {
    // const messaging = getMessaging();
    // onMessage(messaging, (payload) => {
    //   console.log('Message received. ', payload);
    //   // this.message=payload;
    // });
  }
}
// import { Component } from '@angular/core';

// @Component({
//   selector: 'app-autorized-layout',
//   standalone: true,
//   imports: [],
// })

// }

// styleUrl: './autorized-layout.component.scss'
// templateUrl: './autorized-layout.component.html',
// export class AutorizedLayoutComponent {
