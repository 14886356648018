import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject, tap } from 'rxjs';
import { AngularFireMessaging } from '@angular/fire/compat/messaging';
import {
  Messaging,
  deleteToken,
  getMessaging,
  getToken,
  onMessage,
} from '@angular/fire/messaging';
import { environment } from '@environments/environment';
import { initializeApp } from '@angular/fire/app';
import {
  BaseAuthenticatedService,
  httpQueryParams,
} from './base-authenticated.service';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
// import { initializeApp } from "firebase/app";;

@Injectable()
// { providedIn: 'root' }
export class MessagingService extends BaseAuthenticatedService {
  currentMessage = new BehaviorSubject(null);
  message$: Observable<any>;

  constructor(
    private angularFireMessaging: AngularFireMessaging,
    public override auth: AngularFireAuth,
    public override httpClient: HttpClient,
    private router: Router,
    private msg: Messaging
  ) {
    super(auth, httpClient);
    let fire_mess = this.firebaseMessaggingApp;
    onMessage(fire_mess, (payload) => {
      console.log('Message received. ', payload);
      // this.currentMessage.next(payload);
    });
    this.message$ = new Observable((sub) =>
      onMessage(this.msg, (msg) => sub.next(msg))
    ).pipe(
      tap((msg) => {
        console.log('My Firebase Cloud Message', msg);
      })
    );
  }
  async deleteToken() {
    // We can also delete fcm tokens, make sure to also update this on your firestore db if you are storing them as well
    await deleteToken(this.msg);
  }

  public get firebaseMessaggingApp(): Messaging {
    const messaging = getMessaging(initializeApp(environment.firebase));
    return messaging;
  }

  public createDeviceToken<
    T = {
      id: number;
      user: number;
      token: string;
    }
  >(
    // dailyResumeId: number,
    params: httpQueryParams = {},
    data: { [key: string]: any } = {}
  ): Observable<T> {
    let path = `api/notification/devices-token/`;
    return this.create<T>(path, params, data);
  }
  // http://localhost:9577/api/notification/devices-token/

  requestPermission(): Subject<NotificationPermission> {
    let requestPermissionSubject: Subject<NotificationPermission> =
      new Subject();
    try {
      console.log('Requesting permission...', Notification.permission);
      Notification.requestPermission()
        .then((permission: NotificationPermission) => {
          if (permission === 'granted') {
            console.log('Notification permission granted.', Notification);
            // this.getMessagingToken();
          } else if (permission === 'denied') {
            console.log('Notification permission denied.', Notification);
          }
          requestPermissionSubject.next(permission);
        })
        .catch((error) => {
          console.error('Error occurred while requesting permission.', error);
          requestPermissionSubject.error(error);
        });
    } catch (error) {
      console.log('Error occurred while requesting permission.', error);
    }
    return requestPermissionSubject;
  }

  requestToken() {
    console.log('Inside the request token method...');
    this.angularFireMessaging.getToken.subscribe({
      next: (token) => {
        // alert(1234567890)
        console.log(token);
        // console.log('permission granted', token)
      },
      error: (err) => {
        alert(err);
        console.error('Unable to get permission to notify.', err);
      },
    });
  }

  requestForToken = async () => {
    const app = initializeApp(environment.firebase);
    const messaging = getMessaging(app);

    if ('serviceWorker' in navigator) {
      // try {
      //   navigator.serviceWorker
      //     .register('//service-worker/firebase-messaging-sw.js', {
      //       type: 'module',
      //     })
      //     .then(function (registration) {
      //       console.log('Service Worker Registered');
      //     });
      // } catch (error) {
      //   console.error(
      //     'An error occurred while registering the service worker.',
      //     error
      //   );
      // }
      console.log('this.router.url:', this.router.url);
      let _url = new URL(window.location.href);
      console.log('url:', window.location.href);
      console.log('url:', _url);
      console.log('hostname:', _url.protocol);
      console.log('hostname:', _url.host);
      console.log('hostname:', _url.hostname);

      try {
        //   _url.protocol);
        // console.log('hostname:', _url.host
        //   navigator.serviceWorker
        const sw_url = `${_url.protocol}//${_url.host}/firebase-messaging-sw.js`;
        navigator.serviceWorker
          .register(sw_url, {
            // type: 'module',
            scope: `${_url.protocol}//${_url.host}/firebase-cloud-messaging-push-scope`,
          })
          .then(function (registration) {
            console.log('Service Worker Registered');
          });
      } catch (error) {
        console.error(
          'An error occurred while registering the service worker.',
          error
        );
      }
      // try {
      //   navigator.serviceWorker
      //     .register('service-worker/firebase-messaging-sw.js', {
      //       type: 'module',
      //     })
      //     .then(function (registration) {
      //       console.log('Service Worker Registered');
      //     });
      // } catch (error) {
      //   console.error(
      //     'An error occurred while registering the service worker.',
      //     error
      //   );
      // }
    }

    const swReg = await navigator.serviceWorker.getRegistration();
    return getToken(messaging, {
      serviceWorkerRegistration: swReg,
      vapidKey: environment.firebase.vapidKey,
    })
      .then((currentToken) => {
        if (currentToken) {
          console.log('current token for client: ', currentToken);
          // Perform any other neccessary action with the token
          return currentToken;
        } else {
          // Show permission request UI
          console.log(
            'No registration token available. Request permission to generate one.'
          );
        }
        return currentToken;
      })
      .catch((err) => {
        console.log('An error occurred while retrieving token. ', err);
      });
  };
}
